import axiosInstance from '../utils/axios.js';
import axios from "axios";
import {API_URL} from "@/common/config.js";

export const ObjectApi = {
  async postAllObjects({
    from,
    count,
    companies,
    value,
    field,
    dealer,
    conditions,
    sortField,
    sortDesc
  }) {
    const formData = {
      model: 'units',
      companies: companies || [],
      from,
      count,
      field: field || '',
      value: value || ''
    };

    if (dealer && dealer.id) {
      formData.dealer = dealer;
    }
    if (conditions) {
      formData.conditions = conditions;
    }
    if (formData.companies.length <= 0) {
      delete formData.companies;
    }
    if (sortDesc !== '') {
      formData.sortDesc = sortDesc;
      formData.sortField = sortField;
    }

    const { data } = await axiosInstance.post('/admin_query', formData);

    return data;
  },
  async getPorts() {
    const { data } = await axiosInstance.get('/ports');

    return data;
  },

  async getObject() {
    const { data } = await axiosInstance.post('/units/list', {
      fields: [
        'id',
        'name',
        'imei',
        'icon',
        'type.value',
        'type.type',
        'phonenumber',
        'phonenumber2',
        'terminal_type.type',
        'terminal_type.value'
      ]
    });

    return data;
  },
  async getUnitsGroup() {
    const { data } = await axiosInstance.post('/unitsgroup/list', {
      fields: ['id', 'name', 'units.id', 'units.name', 'users.id']
    });

    return data;
  },

  async deleteObjects(ids) {
    const { data } = await axiosInstance.delete(`/units/${ids}`);

    return data;
  },

  async deleteObject(id) {
    const { data } = await axiosInstance.delete(`/units/${id}`);

    return data;
  },

  async getParamsForUnit() {
    const { data } = await axiosInstance.get('/units/sensors/params');

    return data;
  },

  async analyzeWialon({ id, file }) {
    const files = new FormData();
    files.append('file', file);
    const { data } = await axiosInstance.post(
      `/loadwln/${id}/units/analyze_terminals`,
      files
    );

    return data;
  },

  async analyzeParams({ id, terminals }) {
    try {
      const { data } = await axiosInstance.post(
        `/loadwln/${id}/units/analyze_params`,
        terminals
      );

      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  async createParams({ id, info }) {
    const { data } = await axiosInstance.post(
      `/loadwln/${id}/units/create`,
      info
    );

    return data;
  },
  // NEW UNITS
  async getObjectById(id) {
    const { data } = await axiosInstance.get(`/units?ids=${id}`);

    return data;
  },
  async createObject({ formData }) {
    const { data } = await axiosInstance.post('/units', formData);

    return data;
  },
  async updateObject({ id, formData }) {
    const { data } = await axiosInstance.put(`/units/${id}`, formData);

    return data;
  },
  async validateObject({ formData }) {
    const { data } = await axiosInstance.post('/units/validate', formData);

    return data;
  },
  async getCustomFields() {
    const { data } = await axiosInstance.get('/custom_fields');

    return data;
  },
  async postReport(params) {
    const { data } = await axiosInstance.post('/report_any', {
      ...params
    });

    return data;
  },
  async getReportTemplates() {
    const { data } = await axiosInstance.get('/report_templates');

    return data;
  },
  async copySensors(params) {
    const { data } = await axiosInstance.get(
      `/units/sensors/copy?ids=${params.id}`
    );

    return data;
  },
  async unitsItemSensors(id) {
    const { data } = await axiosInstance.get(`/units/${id}/sensors`);

    return data;
  },
  async copySensor(payload) {
    const { data } = await axiosInstance.post(
      `/units/sensors/add_to_units`,
      payload
    );

    return data;
  },

  async getSensorsConnections() {
    const { data } = await axiosInstance.get('/units/sensors/connections');

    return data;
  },

  async postFileToObject(file,companyId) {
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    }
    const { data } = await axiosInstance.post(`/loadwln/${companyId}/units/add_services`,formData);

    return data;
  },

  async postImportHistoryWln(payload) {
    try {
      return await axiosInstance.post(`/tools/loadwln`, payload.file, {
        params: {
          table: payload.table,
          imei: payload.imei,
        }
      })
    } catch (e) {
      console.log(e)
      return e;
    }
  },

  async exportHistory(payload) {
      return await axios.post(API_URL + `/tools/exportwln_multiple`, payload, { responseType: 'blob' });
  }
};
